import {
  useRef, useState, useEffect, useMemo
} from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@hydra/atom/components";

import { isBefore } from "date-fns";
import toast from "react-hot-toast";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getContractTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import {
  getFullName,
  getTestId,
  formatCurrency,
  formatDecimalValues,
} from "@/utils/helpers";
import { getLedgerAccounts } from "@/api/finance/ledgerAccountApi";

const formatContract = (contract) => {
  const data = {
    key: contract.id,
    contract: {
      label: contract.number,
      value: contract.id,
    },
    building: {
      label: contract?.building?.name,
      value: contract?.building?.id,
    },
    unit: contract?.unit.map((unit) => ({
      label: unit?.name,
      value: unit?.id,
    })),
    reservation: {
      label: contract?.reservation?.number,
      value: contract?.reservation?.id,
      total: contract?.reservation?.total,
    },
    totalAmount: formatCurrency(contract.totalContractAmount),
    isSelected: false,
  };

  const { bankAccount } = contract.building;

  if (bankAccount) {
    const { account } = bankAccount;

    data.building.bankAccount = {
      ...bankAccount,
      label: bankAccount.name,
      value: bankAccount.id,
    };

    if (account) {
      data.building.account = {
        label: account.name,
        value: account.key || account.id,
      };
    }
  }
  // move out date / contract termination date filter when exists
  data.outstandingTransaction = contract.paymentDetail
    .filter((p) => !["Cleared", "Collected"].includes(p.paymentStatus))
    .map((payment) => {
      const item = {
        contract: data.contract,
        building: data.building,
        unit: data.unit,
        paymentDate: new Date(payment.paymentDate),
        taxAmount: payment.taxAmount,
        amount: payment.amount,
        originalAmount: payment.totalAmount,
        openBalance: formatDecimalValues(payment.openBalance),
        actualOpenBalance: formatDecimalValues(payment.openBalance),
        adjust: false,
        adjustAmount: "",
        chequeNo: "",
        payment: formatDecimalValues(payment.openBalance),
        paymentDetailId: payment.id,
        paymentStatus: payment.paymentStatus,
        status: {
          label: "Approved",
          value: "Approved",
        },
        account: null,
        description: payment.description,
      };

      if (payment.bank && !isEmpty(payment.bank)) {
        item.bank = {
          label: payment.bank.name,
          value: payment.bank.id,
        };
      }

      return item;
    })
    .sort((a, b) => b.paymentDate - a.paymentDate);
  const dateFilter = contract.moveOutDate || contract?.contractTerminationDate;

  if (dateFilter) {
    data.outstandingTransaction = data.outstandingTransaction.filter((item) => {
      const currentDate = new Date(item.paymentDate);
      return isBefore(currentDate, new Date(dateFilter));
    });
  }

  data.openBalance = formatDecimalValues(
    data.outstandingTransaction.reduce(
      (prevValue, currValue) =>
        Number(prevValue) + Number(currValue.openBalance || 0),
      0
    )
  );

  data.total = data.outstandingTransaction?.reduce(
    (prevValue, currentValue) =>
      Number(prevValue) + Number(currentValue.payment || 0),
    0
  );

  if (contract.reservation?.id && contract?.reservation?.openBalance) {
    const unitLabel = data.unit.map((u) => u.label).join(", ");
    data.credit = [
      {
        adjust: false,
        description: `Reservation for ${unitLabel}`,
        originalAmount: contract?.reservation.total,
        openBalance: contract?.reservation.openBalance,
        payment: "",
        amountAdjusted:
          contract.reservation.total - contract.reservation.openBalance,
        paymentDate: contract?.reservation.reservationDate,
        reservationId: contract?.reservation.id,
      },
    ];
  }

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select contract
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function PaymentReceiptForm() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState({
    isLoading: false,
  });
  const { isOpen, closeModal, openModal } = useModal(false);
  const [contractTableData, setContractTableData] = useState([]);
  const { redirect } = useTaskRedirect();
  const [searchParams] = useSearchParams();
  const isEditing = Boolean(id);
  const contractId = searchParams.get("contract");

  const { data: ledgerAccountsData } = useQuery(["ledger-account"], () =>
    getLedgerAccounts()
  );

  const pdcOnHandAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Post Dated Cheques-On Hand")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const cashCollectionAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Cash Collection - Till")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const creditCardCollectionAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Credit Card Collection - Till")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const onlinePaymentCollectionClearingAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Online Payment Collection Clearing Account")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const { data: contractInfoData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), contractId],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("ContractObjectName"),
        contractId
      ),
    {
      enabled: Boolean(contractId) && !isEditing,
    }
  );

  const { data: contractData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("ContractObjectName")),
      state?.tenant?.value,
    ],
    () =>
      getDynamicObjectRecords(
        kebabCase(dynamicObjectMap.get("ContractObjectName")),
        {
          tenant: state?.tenant?.value,
          takePage: 1,
          limitPage: 15,
          sortBy: "CreatedAt",
          sortType: "DESC",
          queryMode: "Deep",
        }
      ),
    {
      enabled: Boolean(state?.tenant?.value) && !isEditing,
    }
  );

  useEffect(() => {
    if (contractInfoData) {
      const formattedContract = formatContract(contractInfoData);
      formattedContract.tenant = {
        ...contractInfoData?.tenant,
        value: contractInfoData?.tenant?.id,
        label: getFullName(contractInfoData?.tenant),
        lookupObjectName: "Tenant",
      };
      setContractTableData([formattedContract]);
      openModal();
    }
  }, [contractInfoData]);

  useEffect(() => {
    if (contractData && contractData?.data?.length && state?.tenant) {
      const formattedContracts = contractData.data
        .filter((c) => c.status !== "Open")
        .map((c) => formatContract(c));
      setContractTableData(formattedContracts);
      openModal();
    }
  }, [contractData, state?.tenant]);

  const getInstallmentPaidAmount = (installmentId, rowIndex) => {
    const formState = ref.current.getState();
    const { outstandingTransaction } = formState;

    const filteredInstallments = outstandingTransaction.filter(
      (p, index) => p.paymentDetailId === installmentId && index !== rowIndex
    );

    if (filteredInstallments.length) {
      const totalPaidAmount = filteredInstallments.reduce(
        (prevValue, { payment }) => prevValue + Number(payment),
        0
      );

      return totalPaidAmount;
    }

    return 0;
  };

  const setOutstandingTransaction = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { outstandingTransaction } = formState;

    const updatedTransactions = outstandingTransaction.map((o, index) => {
      const {
        actualOpenBalance,
        adjust,
        adjustAmount,
        payment,
        paymentDetailId,
      } = o;
      const paidAmount = getInstallmentPaidAmount(paymentDetailId, index);
      let newOpenBalance = Number(actualOpenBalance) - (paidAmount || 0);

      newOpenBalance -= payment || 0;

      if (adjust && adjustAmount) {
        newOpenBalance -= adjustAmount;
      }

      o.openBalance = newOpenBalance;

      return o;
    });

    const subtotal = updatedTransactions?.reduce(
      (prevValue, currentValue) =>
        Number(prevValue) + Number(currentValue.amount),
      0
    );

    const taxAmount = updatedTransactions?.reduce(
      (prevValue, currentValue) =>
        Number(prevValue) + Number(currentValue.taxAmount || 0),
      0
    );

    const total = updatedTransactions?.reduce(
      (prevValue, currentValue) =>
        Number(prevValue) + Number(currentValue.payment || 0),
      0
    );

    const data = {
      outstandingTransaction: updatedTransactions,
      total,
      subtotal,
      taxAmount,
    };
    ref.current.setFormState(data);
  };

  const setOutstandingTransactionTable = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { outstandingTransactionTable } = formState;

    if (outstandingTransactionTable?.paymentMethod?.value === "Cheque") {
      outstandingTransactionTable.account = {
        label: pdcOnHandAccount.label,
        value: pdcOnHandAccount.value,
      };
    }

    if (outstandingTransactionTable?.paymentMethod?.value === "Cash") {
      outstandingTransactionTable.account = {
        label: cashCollectionAccount.label,
        value: cashCollectionAccount.value,
      };
    }

    if (outstandingTransactionTable?.paymentMethod?.value === "CreditCards") {
      outstandingTransactionTable.account = {
        label: creditCardCollectionAccount.label,
        value: creditCardCollectionAccount.value,
      };
    }

    if (outstandingTransactionTable?.paymentMethod?.value === "OnlinePayment") {
      outstandingTransactionTable.account = {
        label: onlinePaymentCollectionClearingAccount.label,
        value: onlinePaymentCollectionClearingAccount.value,
      };
    }

    const {
      actualOpenBalance,
      adjust,
      adjustAmount,
      payment,
      index,
      paymentDetailId,
    } = outstandingTransactionTable;
    const paidAmount = getInstallmentPaidAmount(paymentDetailId, index);
    let newOpenBalance = Number(actualOpenBalance) - (paidAmount || 0);

    if (adjust && adjustAmount) {
      newOpenBalance -= adjustAmount;
    }

    if (Number(newOpenBalance) < Number(payment)) {
      outstandingTransactionTable.payment = newOpenBalance;
    }

    outstandingTransactionTable.openBalance =
      newOpenBalance - outstandingTransactionTable.payment;

    const data = {
      outstandingTransactionTable,
    };
    ref.current.setFormState(data);
  };

  const setCredit = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { credit } = formState;

    const updatedCredit = credit.map((c) => {
      const {
        adjust, payment, originalAmount, amountAdjusted
      } = c;
      let newOpenBalance = originalAmount - amountAdjusted;

      if (adjust) {
        newOpenBalance -= payment;
      }

      c.openBalance = newOpenBalance;

      return c;
    });

    ref.current.setFormValue("credit", updatedCredit);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        break;

      case "outstandingTransaction":
        setOutstandingTransaction(key, value);
        break;

      case "credit":
        setCredit(key, value);
        break;

      case "outstandingTransactionTable":
        setOutstandingTransactionTable(key, value);
        break;
      default:
        break;
    }
  };

  const onChildStateChange = ({
    key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    const parentFieldState = formState[stateKey] ?? {};
    if (parentField === "outstandingTransaction") {
      switch (key) {
        case "paymentMethod": {
          parentFieldState[key] = value;
          const { paymentMethod } = parentFieldState;

          if (paymentMethod?.value === "WireTransfer") {
            if (!parentFieldState.bankAccount) {
              parentFieldState.account = null;
            }
          } else {
            parentFieldState.bankAccount = null;
          }

          break;
        }
        case "bankAccount": {
          parentFieldState[key] = value;
          const { bankAccount } = parentFieldState;
          const { account } = bankAccount;
          if (account) {
            parentFieldState.account = {
              label: account.name,
              value: account.key || account.id,
            };
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const handleConfirm = () => {
    if (contractTableData.length) {
      const selectedContract = contractTableData.find((r) => r.isSelected);
      ref.current.setFormState(selectedContract);
    }

    closeModal();
  };

  const handleSubmit = () => {
    const submitButton = document.getElementById(
      `dynamic-form-submit-button-${kebabCase(
        dynamicObjectMap.get("PaymentReceiptObjectName")
      )}`
    );
    submitButton.click();
  };

  const onSuccess = (recordId) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));

    redirect(-1, {
      recordId,
      success: true,
    });
  };

  const onError = () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const onBeforeSave = () => {
    const formState = ref.current.getState();
    const { outstandingTransaction } = formState;

    if (!outstandingTransaction || !outstandingTransaction.length) {
      toast.error("Outstanding transaction is required");
      return false;
    }

    const transactionsWithZeroPayment = outstandingTransaction.filter(
      (o) => Number(o.payment || 0) === 0
    );

    if (transactionsWithZeroPayment.length) {
      toast.error(
        `Payment amount for one or more payments is 0.
         Please enter a larger number or remove the payment from table.`
      );
      return false;
    }

    const transactionsWithoutChequeOrBank = outstandingTransaction.filter(
      (o) => {
        if (o.paymentMethod && o.paymentMethod.value === "Cheque") {
          if (!o.chequeNo || !o.bank || isEmpty(o.bank)) {
            return true;
          }
        }

        return false;
      }
    );

    if (transactionsWithoutChequeOrBank.length) {
      toast.error(
        "Cheque No. or Bank for some payment is missing"
      );
      return false;
    }

    return true;
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant Contract"
        subtitle="Selected tenant has following open contracts"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="x-large"
      >
        {contractTableData.length ? (
          <TableWithCheckbox
            data={contractTableData}
            columns={getContractTableColumns()}
            setData={setContractTableData}
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("PaymentReceiptObjectName")}
        showHeader
        showLinkedViews
        onSuccess={onSuccess}
        onError={onError}
        navigate={false}
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        showButtons={false}
        onBeforeSave={onBeforeSave}
        setIsLoading={(isLoading) =>
          setState((prevState) => ({
            ...prevState,
            isLoading,
          }))}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(contractTableData.length),
              ...props,
            }),
        }}
      />
      <div className="buttons-container buttons-at-end">
        <Button
          small
          bordered
          onClick={() => navigate(-1)}
          testId={getTestId(
            `${dynamicObjectMap.get("PaymentReceiptObjectName")}-Submit-Button`
          )}
        >
          Cancel
        </Button>
        <Button
          small
          onClick={handleSubmit}
          loading={state.isLoading}
          testId={getTestId(
            `${dynamicObjectMap.get("PaymentReceiptObjectName")}-Submit-Button`
          )}
        >
          Save
        </Button>
      </div>
    </BoxedContent>
  );
}

export default PaymentReceiptForm;
