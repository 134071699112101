import {
  useRef, useState, useEffect, useCallback, useMemo
} from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  differenceInDays,
  intervalToDuration,
  isFuture,
  isToday,
  subDays,
} from "date-fns";

import { useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
  bulkCreateDynamicObjectRecord,
  deleteDynamicObjectRecord,
} from "@/api/dynamic/dynamicObjectNameApi";
import { prepareDynamicObjectState, prepareFieldValue } from "@/utils/dynamic/helpers";
import { calculateEndDateWithoutGracePeriod, calculateContractAmount } from "@/utils/leasing/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useCompanyAccount } from "@/hooks";
import { getCompany } from "@/api/user/authApi";

import useTaskRedirect from "@/hooks/useTaskRedirect";
import {
  getFullName,
  getPaymentDate,
  calculateTax,
  formatDecimalValues,
  formatCurrency,
  formatDate
} from "@/utils/helpers";
import { exemptedTaxTypes } from "@/utils/finance/constants";
import { getTaxRules } from "@/api/finance/taxRuleApi";

const exchangeRate = {
  AED: 1,
};

const calculateDefaultEndDate = ({ startDate, duration, noOfDuration }) => {
  let contractEndDate = null;

  if (!startDate) {
    return contractEndDate;
  }

  if (!duration || !noOfDuration) return contractEndDate;

  switch (duration.value) {
    case "Nights":
      contractEndDate = addDays(startDate, Number(noOfDuration));
      break;

    case "Months":
      contractEndDate = addMonths(startDate, Number(noOfDuration));
      break;

    case "Quarters":
      contractEndDate = addQuarters(startDate, Number(noOfDuration));
      break;

    case "Years":
      contractEndDate = addYears(startDate, Number(noOfDuration));
      break;

    default:
      break;
  }

  if (duration.value !== "Nights") {
    contractEndDate = subDays(contractEndDate, 1);
  }

  return contractEndDate;
};

const calculateCustomEndDate = ({
  startDate, durationYears, durationMonths, durationDays
}) => {
  let contractEndDate = null;

  if (!startDate) {
    return contractEndDate;
  }

  if (durationYears) {
    contractEndDate = addYears(startDate, Number(durationYears));
  }

  if (durationMonths) {
    contractEndDate = addMonths(startDate, Number(durationMonths));
  }

  if (durationDays) {
    contractEndDate = addDays(startDate, Number(durationDays));
  }

  if (durationYears || durationMonths) {
    contractEndDate = subDays(contractEndDate, 1);
  }

  return contractEndDate;
};

const addGracePeriodToEndDate = ({ endDate, gracePeriodDays }) => {
  if (!endDate) {
    return null;
  }

  if (!gracePeriodDays) {
    return endDate;
  }

  const contractEndDate = addDays(new Date(endDate), Number(gracePeriodDays));

  return contractEndDate;
};

const calculateRentChange = ({ type, percentage, amount }) => {
  const changedAmount = amount * (percentage / 100);
  let newAmount = amount;

  switch (type.value) {
    case "Increase":
      newAmount += changedAmount;
      break;

    default:
      newAmount -= changedAmount;
      break;
  }

  return {
    newAmount,
    changedAmount,
  };
};

const formatNewContract = (contract, prevContract, newRentAmount) => {
  const { adjustAmountDetail } = contract;
  contract.isRenewingContract = true;
  contract.actualRentAmount = newRentAmount;
  contract.prevRentAmount = contract.annualAmount;
  contract.annualAmount = newRentAmount;
  const changedAmount = newRentAmount - contract.prevRentAmount;
  contract.increasedRentAmount = Math.abs(changedAmount);

  if (changedAmount === 0) {
    contract.rentChangeType = {
      label: "No Change",
      value: "NoChange",
    };
  } else if (changedAmount > 0) {
    contract.rentChangeType = {
      label: "Increase",
      value: "Increase",
    };
  } else {
    contract.rentChangeType = {
      label: "Decrease",
      value: "Decrease",
    };
  }

  contract.rentChangePercentage = formatDecimalValues(
    (Math.abs(changedAmount) / contract.prevRentAmount) * 100
  );
  contract.renewedFrom = prevContract;
  contract.externalNumber = "";

  if (!contract?.durationType) {
    contract.durationType = {
      label: "Default",
      value: "Default",
    };
  }
  contract.contractInitiationType = {
    label: "Renewed",
    value: "Renewed",
  };
  contract.status = {
    label: "Open",
    value: "Open",
  };
  contract.agreementStartDate = addDays(new Date(contract.agreementEndDate), 1);

  if (contract?.durationType?.value === "Default") {
    const agreementEndDate = calculateDefaultEndDate({
      startDate: contract.agreementStartDate,
      duration: contract.duration,
      noOfDuration: contract.noOfDuration,
    });

    const agreementEndDateWithGracePeriod = addGracePeriodToEndDate({
      endDate: agreementEndDate,
      gracePeriodDays: contract.gracePeriodDays || 0,
    });

    contract.agreementEndDate = agreementEndDateWithGracePeriod;
  } else if (contract?.durationType?.value === "Custom") {
    const agreementEndDate = calculateCustomEndDate({
      startDate: contract.agreementStartDate,
      durationYears: contract.durationYears,
      durationMonths: contract.durationMonths,
      durationDays: contract.durationDays,
    });

    contract.agreementEndDate = agreementEndDate;
  }

  contract.agreementEndDateWithoutGracePeriod = calculateEndDateWithoutGracePeriod(
    contract.agreementEndDate,
    contract.gracePeriodDays
  );

  contract.noOfDays =
    differenceInDays(contract.agreementEndDateWithoutGracePeriod, contract.agreementStartDate) + 1;

  contract.docDate = new Date();

  if (adjustAmountDetail && adjustAmountDetail.length) {
    const firstAdjustment = adjustAmountDetail[0];
    const { adjustType, percentage } = firstAdjustment;

    contract.rentChangeType = adjustType;
    contract.rentChangePercentage = percentage;

    const adjustmentDetail = contract.adjustAmountDetail.slice(0, adjustAmountDetail.length - 1);
    contract.adjustAmountDetail = adjustmentDetail;
    contract.adjustAmountDuration -= 1;
  }

  const contractAmount = calculateContractAmount({
    annualAmount: contract.annualAmount,
    startDate: contract.agreementStartDate,
    endDate: contract.agreementEndDate,
    gracePeriodDays: contract.gracePeriodDays,
  });

  contract.contractAmount = contractAmount;
  contract.amountBeforeTax = contractAmount;

  const utilityChargeDetail = contract.utilityChargeDetail.map((item) => {
    const { utilityType } = item;

    const selectedUtilityType = {
      ...utilityType,
      label: utilityType.name,
      value: utilityType.id,
    };

    item.utilityType = selectedUtilityType;

    const { amount, amountPercentage, amountType } = utilityType;

    if (amountType === "Amount") {
      item.amount = amount;
    } else if (amountType === "Percentage") {
      const utilAmount = (contract.contractAmount * amountPercentage) / 100;
      item.amount = formatDecimalValues(utilAmount);
    }

    return item;
  });

  contract.utilityChargeDetail = utilityChargeDetail;

  contract.utilityCharge = utilityChargeDetail.reduce(
    (total, currentValue) => total + Number(currentValue.amount),
    0
  );
  contract.utilityAmountBeforeTax = contract.utilityCharge;

  if (contract.utilityTax) {
    const amountToTax = contract.utilityCharge;

    const { taxAmount, principalAmount } = calculateTax({
      amount: amountToTax,
      amountOfTax: contract.utilityAmountOfTax,
      tax: contract.utilityTax,
      taxAmount: "",
    });

    contract.utilityTaxAmount = taxAmount;
    contract.utilityAmountBeforeTax = principalAmount;
    contract.totalUtilityCharge = formatDecimalValues(
      Number(taxAmount) + Number(contract.utilityAmountBeforeTax)
    );
  } else {
    contract.utilityTaxAmount = 0;
    contract.totalUtilityCharge = contract.utilityAmountBeforeTax;
  }

  if (contract.tax) {
    const amountToTax = contractAmount;

    const { taxAmount, principalAmount } = calculateTax({
      amount: amountToTax,
      amountOfTax: contract.amountOfTax,
      tax: contract.tax,
      taxAmount: "",
    });

    contract.taxAmount = taxAmount;
    contract.amountBeforeTax = principalAmount;
    contract.totalContractAmount = Number(taxAmount) + Number(contract.amountBeforeTax);
  } else {
    contract.taxAmount = 0;
    contract.totalContractAmount = contract.amountBeforeTax;
  }

  delete contract.number;
  delete contract.noOfPayments;
  contract.paymentDetail = [];
  contract.attachment = [];
  contract.tawtheeqAttachments = [];
  contract.nOCAttachments = [];
  contract.utilityCertificateAttachments = [];
  contract.otherAttachments = [];

  if (contract.unitAnnualAmount && contract.unitAnnualAmount.length) {
    const unitAnnualAmounts = contract.unitAnnualAmount.map((a) => {
      const annualAmount = a.contractUnit.newRentAmount || a.contractUnit.totalRentAmount;
      const unitContractAmount = formatDecimalValues(
        (Number(annualAmount) / Number(contract.annualAmount)) * Number(contract.contractAmount)
      );

      return {
        company: {
          label: a.company.name,
          value: a.company.id,
        },
        annualAmount,
        contractAmount: unitContractAmount,
        contractUnit: {
          label: a.contractUnit.name,
          value: a.contractUnit.id,
        },
      };
    });
    contract.unitAnnualAmount = unitAnnualAmounts;
  }

  contract.contractTerminationDate = null;
  contract.moveOutDate = null;
  contract.recognizedRentalRevenue = 0;
  contract.recognizedUtilityRevenue = 0;
  contract.lastRentalRevenueRecognitionDate = 0;
  contract.lastUtilityRevenueRecognitionDate = 0;
  contract.receivedRentAmount = 0;
  contract.utilityAmountForStayedPeriod = 0;
  contract.receivedUtilityAmount = 0;
  contract.vatAmountForStayedPeriod = 0;
  contract.receivedVatAmount = 0;
  contract.refundAmount = 0;
  contract.earlyTerminationPenalty = 0;
  contract.releaseDate = null;
  contract.releaseReason = null;
  contract.releaseType = null;
  contract.discontinuedBy = null;
  contract.continuityMode = null;
  contract.renewingAgreementStartDate = null;

  return contract;
};

const formatUnit = (units, formState) => {
  const {
    agreementStartDate, agreementEndDate, gracePeriodDays, tenant
  } = formState;

  const data = {
    securityDeposit: 0,
    annualAmount: 0,
    amountBeforeTax: "",
    totalContractAmount: "",
    subtotal: "",
    contractAmount: "",
  };

  let combinedUnitArea = 0;

  units.forEach((unit) => {
    const {
      totalRentAmount, securityDeposit, tax, totalUnitArea, unitProperty, newRentAmount
    } =
      unit;

    data.annualAmount += newRentAmount || totalRentAmount;

    if (securityDeposit > 0) {
      data.securityDeposit += securityDeposit;
    }

    if (unitProperty && !isEmpty(unitProperty)) {
      if (["Residential", "Commercial"].includes(unitProperty)) {
        data.contractType = {
          label: unitProperty,
          value: unitProperty,
        };
      }
    }

    if (tax && !isEmpty(tax) && !exemptedTaxTypes.includes(tenant?.type)) {
      data.tax = {
        ...tax,
        label: tax?.name,
        value: tax?.id,
      };
    }

    combinedUnitArea += Number(totalUnitArea);
  });

  if (agreementStartDate && agreementEndDate) {
    const contractAmount = calculateContractAmount({
      annualAmount: data.annualAmount,
      startDate: agreementStartDate,
      endDate: agreementEndDate,
      gracePeriodDays,
    });

    data.contractAmount = contractAmount;
    data.amountBeforeTax = contractAmount;
    data.subtotal = contractAmount;
    data.totalContractAmount = contractAmount;
  }

  if (combinedUnitArea > 0) {
    data.ratePerUnitOfArea = formatDecimalValues(data.annualAmount / combinedUnitArea);
  }

  return data;
};

const formatReservation = (reservation) => {
  const data = {
    isValid: false,
  };

  if (reservation?.status !== "Paid") {
    return data;
  }

  const reservationEndDate = reservation?.reservationEndDate;

  if (isFuture(new Date(reservationEndDate)) || isToday(new Date(reservationEndDate))) {
    data.reservation = {
      label: reservation?.number,
      value: reservation?.id,
    };

    data.building = {
      label: reservation?.building?.name,
      value: reservation?.building?.id,
    };

    data.unit = reservation?.unit?.map((unit) => ({
      ...unit,
      label: unit?.name,
      value: unit?.id,
    }));

    data.salesLocation = {
      label: reservation?.salesLocation?.name,
      value: reservation?.salesLocation?.id,
    };

    data.isValid = true;
  }

  return data;
};

const formatTenant = (tenant) => {
  let data = {
    tenant: {
      label: getFullName(tenant),
      value: tenant?.id,
      email: tenant?.email,
      address: tenant?.address,
    },
  };

  if (!isEmpty(tenant.currency) && tenant.currency) {
    data.currency = {
      label: tenant.currency.code,
      value: tenant.currency.id,
    };

    if (exchangeRate[tenant.currency.code]) {
      data.exchangeRate = exchangeRate[tenant.currency.code];
    } else {
      data.exchangeRate = 1;
    }
  }

  const { reservation } = tenant;

  if (reservation && !isEmpty(reservation)) {
    const reservationData = formatReservation(reservation);
    if (reservationData.isValid) {
      data = {
        ...data,
        ...reservationData,
      };
    }
  }

  return data;
};

const reservationInitialState = {
  reservation: null,
  salesLocation: null,
};

const preparePaymentDetail = (formState, taxRulesData) => {
  const {
    contractAmount,
    securityDeposit,
    agreementStartDate,
    agreementEndDate,
    tax,
    amountOfTax,
    utilityCharge,
    utilityTax,
    utilityTaxAmount,
    totalUtilityCharge,
    noOfPayments,
    noOfUtilityPayments,
    adminCharges,
    adminChargesTax,
  } = formState;

  if (!contractAmount || !agreementStartDate || !agreementEndDate || !parseInt(noOfPayments, 10)) {
    return;
  }

  const paymentDetail = [];
  if (totalUtilityCharge > 0 && noOfUtilityPayments) {
    for (let i = noOfUtilityPayments - 1; i >= 0; i -= 1) {
      const paymentAmount = formatDecimalValues(utilityCharge / noOfUtilityPayments);
      const paymentTaxAmount = formatDecimalValues(utilityTaxAmount / noOfUtilityPayments);
      const totalPaymentAmount = Number(paymentAmount) + Number(paymentTaxAmount);

      paymentDetail.push({
        paymentDate: getPaymentDate({
          agreementStartDate,
          agreementEndDate,
          index: i,
          noOfPayments: noOfUtilityPayments,
        }),
        utilityAmount: paymentAmount,
        securityDepositAmount: 0,
        rentAmount: 0,
        amount: paymentAmount,
        tax: utilityTax,
        taxAmount: paymentTaxAmount,
        totalAmount: totalPaymentAmount,
        description: `Other Charges Payment ${i + 1}`,
        // Hidden fields
        openBalance: totalPaymentAmount,
        paymentStatus: {
          label: "Pending",
          value: "Pending",
        },
        paymentType: {
          label: "Utility",
          value: "Utility",
        },
        invoiceStatus: {
          label: "Not Generated",
          value: "NotGenerated",
        },
      });
    }
  }

  const totalAmount = contractAmount;
  let amount = formatDecimalValues(totalAmount / noOfPayments);
  let taxAmount = 0;

  if (tax && amountOfTax) {
    const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax({
      amount,
      amountOfTax,
      tax,
      taxAmount: "",
    });
    amount = principalAmount;
    taxAmount = calculatedTaxAmount;
  }

  for (let i = noOfPayments - 1; i >= 0; i -= 1) {
    const paymentAmount = amount;
    const paymentTaxAmount = taxAmount;
    const utilityAmount = 0;
    const securityDepositAmount = 0;
    paymentDetail.push({
      paymentDate: getPaymentDate({
        agreementStartDate,
        agreementEndDate,
        index: i,
        noOfPayments,
      }),
      utilityAmount,
      securityDepositAmount,
      rentAmount: amount,
      amount: paymentAmount,
      tax,
      taxAmount: paymentTaxAmount,
      totalAmount: Number(paymentAmount) + Number(paymentTaxAmount),
      description: `Installment ${i + 1} from ${formatDate(getPaymentDate({
        agreementStartDate,
        agreementEndDate,
        index: i,
        noOfPayments,
      }))} to ${noOfPayments - 1 === i ? formatDate(agreementEndDate) :
        formatDate(subDays(new Date((getPaymentDate({
          agreementStartDate,
          agreementEndDate,
          index: noOfPayments - 1 === i ? i : i + 1,
          noOfPayments,
        }))), 1))} `,
      // Hidden fields
      openBalance: Number(paymentAmount) + Number(paymentTaxAmount),
      paymentStatus: {
        label: "Pending",
        value: "Pending",
      },
      paymentType: {
        label: "Rent",
        value: "Rent",
      },
      invoiceStatus: {
        label: "Not Generated",
        value: "NotGenerated",
      },
    });
  }
  if (securityDeposit > 0 && !formState.isRenewingContract) {
    const paymentAmount = securityDeposit;
    const paymentTaxAmount = 0;
    const utilityAmount = 0;
    const securityDepositAmount = securityDeposit;

    const taxItem = taxRulesData?.data.find((i) => i.name === "Out of Scope of Tax");

    paymentDetail.push({
      paymentDate: getPaymentDate({
        agreementStartDate,
        agreementEndDate,
        index: 0,
        noOfPayments,
      }),
      utilityAmount,
      securityDepositAmount,
      rentAmount: 0,
      amount: paymentAmount,
      tax: {
        label: taxItem?.name,
        value: taxItem?.id,
      },
      taxAmount: paymentTaxAmount,
      totalAmount: Number(paymentAmount) + Number(paymentTaxAmount),
      description: "Security Deposit",
      // Hidden fields
      openBalance: Number(paymentAmount) + Number(paymentTaxAmount),
      paymentStatus: {
        label: "Pending",
        value: "Pending",
      },
      paymentType: {
        label: "SecurityDeposit",
        value: "SecurityDeposit",
      },
      invoiceStatus: {
        label: "Not Generated",
        value: "NotGenerated",
      },
    });
  }

  if (adminCharges > 0) {
    let paymentAmount = adminCharges;
    let paymentTaxAmount = 0;

    if (adminChargesTax && amountOfTax) {
      const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax({
        amount: adminCharges,
        amountOfTax,
        tax: adminChargesTax,
        taxAmount: "",
      });
      paymentAmount = principalAmount;
      paymentTaxAmount = calculatedTaxAmount;
    }

    paymentDetail.push({
      paymentDate: getPaymentDate({
        agreementStartDate,
        agreementEndDate,
        index: 0,
        noOfPayments,
      }),
      utilityAmount: 0,
      securityDepositAmount: 0,
      adminChargesAmount: Number(paymentAmount),
      rentAmount: 0,
      amount: paymentAmount,
      tax: adminChargesTax,
      taxAmount: paymentTaxAmount,
      totalAmount: Number(paymentAmount) + Number(paymentTaxAmount),
      description: "Admin Charges",
      // Hidden fields
      openBalance: Number(paymentAmount) + Number(paymentTaxAmount),
      paymentStatus: {
        label: "Pending",
        value: "Pending",
      },
      paymentType: {
        label: "Admin Charges",
        value: "AdminCharges",
      },
      invoiceStatus: {
        label: "Not Generated",
        value: "NotGenerated",
      },
    });
  }

  return paymentDetail;
};

function ContractForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState({});
  const { redirect } = useTaskRedirect();
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false,
    },
  });
  const [searchParameters] = useSearchParams();
  const tenantId = searchParameters.get("tenant");
  const contractId = searchParameters.get("contract");
  const isRenewingContract = searchParameters.get("renewContract");
  const { id } = useParams();
  const isEditing = Boolean(id);
  const queryClient = useQueryClient();

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);

  const { data: schema } = useQuery(
    ["dynamic-object-camelized-schema", dynamicObjectMap.get("ContractPaymentDetailObjectName")],
    () =>
      getDynamicObjectByNameWithCamelizedFieldNames(
        dynamicObjectMap.get("ContractPaymentDetailObjectName")
      ),
    {
      enabled: isEditing,
    }
  );

  const saveBulkMutation = useMutation(({ objectName, dataObjects }) =>
    bulkCreateDynamicObjectRecord(objectName, dataObjects)
  );

  const deleteMutation = useMutation(({ key, objectName }) =>
    deleteDynamicObjectRecord(objectName, key)
  );

  const { data: reservationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ReservationObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ReservationObjectName")), {
        lead: state?.tenant?.value,
        takePage: 1,
        limitPage: 5,
        sortBy: "CreatedAt",
        sortType: "DESC",
        "status[in]": "Approved,Paid",
      }),
    {
      enabled: Boolean(state?.tenant?.value),
    }
  );

  const { data: tenantData } = useQuery(
    [kebabCase(dynamicObjectMap.get("TenantObjectName")), tenantId || state?.tenant?.value],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("TenantObjectName")),
        tenantId || state?.tenant?.value
      ),
    {
      enabled: Boolean(tenantId) || Boolean(state?.tenant?.value),
    }
  );

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), contractId],
    () =>
      getDynamicObjectRecordById(kebabCase(dynamicObjectMap.get("ContractObjectName")), contractId),
    {
      enabled: Boolean(contractId),
    }
  );

  const { data: objectSchema } = useQuery(
    ["dynamic-object-camelized-schema", kebabCase(dynamicObjectMap.get("ContractObjectName"))],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("ContractObjectName"))
  );

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const {
        contractBreakPenalty, customerRefund, damageBreakPenalty, pdcOnHand, pdcContra
      } =
        defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        pdcOnHandAccount: pdcOnHand,
        pdcContraAccount: pdcContra,
        refundAccount: customerRefund,
        contractTerminationAccount: contractBreakPenalty,
        damagePenaltyAccount: damageBreakPenalty,
      }));
    }
  }, [defaultAccounts]);

  const setUnitAnnualAmount = async (value) => {
    if (!id) {
      const unitAnnualAmount = value.map((unit) => ({
        company: {
          value: getCompany(),
        },
        contractUnit: unit,
        annualAmount: unit.newRentAmount || unit.totalRentAmount,
      }));

      // Only set unit annual amount when creating the contract
      ref.current.setFormValue("unitAnnualAmount", unitAnnualAmount);
    } else {
      const { unitAnnualAmount: unitAnnualAmountState } = ref.current.getState();
      const unitAnnualAmount = unitAnnualAmountState ?? [];

      const unitIds = value?.map((u) => u.value);
      const existingUnitIds = unitAnnualAmount.map((u) => u.contractUnit.value);
      const removedUnits = unitAnnualAmount.filter((u) => !unitIds.includes(u.contractUnit.value));

      // Delete annual amount for removed units
      if (removedUnits.length) {
        removedUnits.forEach((removedUnitAmount) => {
          deleteMutation.mutate({
            objectName: dynamicObjectMap.get("ContractUnitAnnualAmountObjectName"),
            key: removedUnitAmount.id,
          });
        });
      }

      const addedUnits = value.filter((u) => !existingUnitIds.includes(u.value));
      let newUnitAnnualAmounts = [];

      // Add annual amounts for newly selected units
      if (addedUnits.length) {
        const dataObjects = addedUnits.map((u) => ({
          company: getCompany(),
          detailId: id,
          contractUnit: u.value,
          annualAmount: u.newRentAmount || u.totalRentAmount,
        }));

        const response = await saveBulkMutation.mutateAsync({
          objectName: dynamicObjectMap.get("ContractUnitAnnualAmountObjectName"),
          dataObjects,
        });

        newUnitAnnualAmounts = addedUnits.map((u, i) => {
          const dataObject = {
            detailId: id,
            contractUnit: u,
            annualAmount: u.newRentAmount || u.totalRentAmount,
            id: response.data[i].id,
            company: {
              value: getCompany(),
            },
          };

          return dataObject;
        });
      }

      const existingAnnualAmounts = unitAnnualAmount.filter((u) =>
        unitIds.includes(u.contractUnit.value)
      );

      newUnitAnnualAmounts = newUnitAnnualAmounts.concat(existingAnnualAmounts);
      ref.current.setFormValue("unitAnnualAmount", newUnitAnnualAmounts);
    }
  };

  useEffect(() => {
    if (contractData && objectSchema) {
      const { unit } = contractData;
      const newRentAmount = unit.reduce(
        (total, currentValue) =>
          total + Number(currentValue.newRentAmount || currentValue.totalRentAmount),
        0
      );
      const recordState = prepareDynamicObjectState(contractData, objectSchema?.document, true);
      const prevContract = {
        label: contractData?.number,
        value: contractData?.id,
      };
      const formattedContract = formatNewContract(recordState, prevContract, newRentAmount);
      setInitialState((prevState) => ({
        ...prevState,
        ...formattedContract,
      }));
      setUnitAnnualAmount(formattedContract.unit);
    }
  }, [contractData, objectSchema]);

  const setUnitData = (value) => {
    const formState = ref.current.getState();
    const unitData = formatUnit(value, formState);
    ref.current.setFormState(unitData);
  };

  useEffect(() => {
    if (tenantData && !id) {
      const formattedTenant = formatTenant(tenantData);

      if (formattedTenant.reservation && !isEmpty(formattedTenant.reservation)) {
        const taxItem = taxRulesData?.data
          .filter((t) => t.saleCollected)
          .find((t) => t.region === formattedTenant.salesLocation?.value);

        if (taxItem) {
          formattedTenant.utilityTax = {
            ...taxItem,
            label: taxItem.name,
            value: taxItem.id,
          };

          formattedTenant.adminChargesTax = {
            ...taxItem,
            label: taxItem.name,
            value: taxItem.id,
          };
        }
      }

      if (tenantId) {
        setState({
          ...state,
          tenant: formattedTenant,
        });
        ref.current.setFormState(formattedTenant);
      } else {
        ref.current.setFormState(formattedTenant);
      }

      if (formattedTenant.unit) {
        setUnitData(formattedTenant.unit);
      }
    }
  }, [tenantData]);

  useEffect(() => {
    if (reservationData && state?.tenant && !id) {
      let formattedReservations = [];

      if (reservationData?.data && reservationData?.data?.length) {
        formattedReservations = reservationData?.data
          .map((r) => formatReservation(r))
          .filter((r) => r.isValid);
      }

      if (formattedReservations.length) {
        const selectedReservation = formattedReservations[0];
        const taxItem = taxRulesData?.data
          .filter((t) => t.saleCollected)
          .find((t) => t.region === selectedReservation.salesLocation?.value);

        if (taxItem) {
          selectedReservation.utilityTax = {
            ...taxItem,
            label: taxItem.name,
            value: taxItem.id,
          };

          selectedReservation.adminChargesTax = {
            ...taxItem,
            label: taxItem.name,
            value: taxItem.id,
          };
        }
        ref.current.setFormState(selectedReservation);
        setUnitData(selectedReservation.unit);
      }
    }
  }, [reservationData, state?.tenant]);

  const setBuildingData = (key, value) => {
    const data = {
      salesLocation: null,
    };

    const { emirate } = value;

    if (!isEmpty(emirate)) {
      data.salesLocation = {
        label: emirate?.name,
        value: emirate?.id,
      };

      const taxItem = taxRulesData?.data
        .filter((t) => t.saleCollected)
        .find((t) => t.region === emirate?.id);

      if (taxItem) {
        data.utilityTax = {
          ...taxItem,
          label: taxItem.name,
          value: taxItem.id,
        };

        data.adminChargesTax = {
          ...taxItem,
          label: taxItem.name,
          value: taxItem.id,
        };
      }
    }

    ref.current.setFormState(data);
  };

  const setPaymentDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const paymentDetail = preparePaymentDetail(formState, taxRulesData);
    ref.current.setFormValue("paymentDetail", paymentDetail);
  };

  const updatePaymentDetail = async (key, value) => {
    const isRentPayments = key === "noOfPayments";
    const isUtilityPayments = key === "noOfUtilityPayments";

    const toastId = toast.loading("Generating Payments...");
    const formState = ref.current.getState();
    formState[key] = value;
    let newPaymentDetail = preparePaymentDetail(formState, taxRulesData);
    const { paymentDetail } = formState;
    const dataObjects = [];

    try {
      if (newPaymentDetail) {
        if (isRentPayments) {
          newPaymentDetail = newPaymentDetail.filter((p) => p.paymentType.value !== "Utility");
        }

        if (isUtilityPayments) {
          newPaymentDetail = newPaymentDetail.filter((p) => p.paymentType.value === "Utility");
        }

        newPaymentDetail.forEach((payment) => {
          const dataObject = {};

          schema?.document.forEach((field) => {
            dataObject[field.name] = prepareFieldValue(field, payment);
          });

          dataObject.detailId = id;
          dataObject.company = getCompany();

          dataObjects.push(dataObject);
        });
      }

      if (paymentDetail) {
        if (isRentPayments) {
          paymentDetail.filter((p) => p.paymentType.value !== "Utility").forEach((payment) => {
            deleteMutation.mutate({
              objectName: dynamicObjectMap.get("ContractPaymentDetailObjectName"),
              key: payment.id,
            });
          });
        }

        if (isUtilityPayments) {
          paymentDetail.filter((p) => p.paymentType.value === "Utility").forEach((payment) => {
            deleteMutation.mutate({
              objectName: dynamicObjectMap.get("ContractPaymentDetailObjectName"),
              key: payment.id,
            });
          });
        }
      }

      if (dataObjects.length) {
        const response = await saveBulkMutation.mutateAsync({
          objectName: dynamicObjectMap.get("ContractPaymentDetailObjectName"),
          dataObjects,
        });

        newPaymentDetail = newPaymentDetail.map((payment, i) => {
          payment.id = response.data[i].id;

          return payment;
        });
      }
    } catch (error) {
      toast.error("Could not generate payments. Try again!", {
        id: toastId,
      });
    }

    toast.success("Payments generated!", {
      id: toastId,
    });

    queryClient.invalidateQueries({
      queryKey: [kebabCase(dynamicObjectMap.get("ContractPaymentDetailObjectName"))],
    });

    if (isRentPayments) {
      const filteredPaymentDetail = paymentDetail.filter((p) => p.paymentType.value === "Utility");
      newPaymentDetail = newPaymentDetail.concat(filteredPaymentDetail);
    }

    if (isUtilityPayments) {
      const filteredPaymentDetail = paymentDetail.filter((p) => p.paymentType.value !== "Utility");
      newPaymentDetail = newPaymentDetail.concat(filteredPaymentDetail);
    }

    ref.current.setFormValue("paymentDetail", newPaymentDetail);
  };

  const noOfPaymentsField = useMemo(
    () =>
      (objectSchema ? objectSchema.document.find((f) => f.camelizedName === "noOfPayments") : null),
    [objectSchema]
  );

  const noOfUtilityPaymentsField = useMemo(
    () =>
      (objectSchema ?
        objectSchema.document.find((f) => f.camelizedName === "noOfUtilityPayments") :
        null),
    [objectSchema]
  );

  const generatePayments = (key, value) => {
    const formState = ref.current.getState();
    const { paymentDetail } = formState;
    let paymentValue = value;
    if (
      (key === "noOfPayments" && noOfPaymentsField.maximum < value) ||
      (key === "noOfUtilityPayments" && noOfUtilityPaymentsField.maximum < value)
    ) {
      paymentValue =
        key === "noOfPayments" ?
          `${noOfPaymentsField.maximum}` :
          `${noOfUtilityPaymentsField.maximum}`;
    }

    if (key === "noOfPayments" && isEditing) {
      const filteredPaymentDetail = paymentDetail.filter((p) => p.paymentType.value !== "Utility");

      const isAnyPaymentCollectedOrInvoiceGenerated = filteredPaymentDetail.filter((p) => {
        if (p.invoiceStatus.value === "Generated") {
          return true;
        }

        if (Number(p.openBalance) !== Number(p.totalAmount)) {
          return true;
        }

        return false;
      });

      if (isAnyPaymentCollectedOrInvoiceGenerated.length) {
        toast.error("Cannot update payments as some payments are collected or invoice is generated");
        return;
      }
    }

    if (key === "noOfUtilityPayments" && isEditing) {
      const filteredPaymentDetail = paymentDetail.filter((p) => p.paymentType.value === "Utility");

      const isAnyPaymentCollectedOrInvoiceGenerated = filteredPaymentDetail.filter((p) => {
        if (p.invoiceStatus.value === "Generated") {
          return true;
        }

        if (Number(p.openBalance) !== Number(p.totalAmount)) {
          return true;
        }

        return false;
      });

      if (isAnyPaymentCollectedOrInvoiceGenerated.length) {
        toast.error("Cannot update payments as some payments are collected or invoice is generated");
        return;
      }
    }

    ref.current.setFormValue(key, paymentValue);

    if (isEditing) {
      updatePaymentDetail(key, paymentValue);
      return;
    }

    setPaymentDetail(key, paymentValue);
  };

  const calculateContractAmountInUnitAnnualAmount = (data) => {
    const formState = ref.current.getState();
    const { unitAnnualAmount } = formState;
    const unitAnnualAmountData = unitAnnualAmount?.map((item) => {
      item.contractAmount = formatDecimalValues(
        (Number(item.annualAmount) / Number(data.annualAmount)) * Number(data?.contractAmount)
      );
      return item;
    });
    data.unitAnnualAmount = unitAnnualAmountData;
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      tax,
      amountOfTax,
      annualAmount,
      agreementStartDate,
      agreementEndDate,
      gracePeriodDays,
      unit,
      adminCharges,
      adminChargesTax,
    } = formState;

    const data = {
      annualAmount,
      totalContractAmount: "",
      taxAmount: "",
      amountBeforeTax: "",
      adminChargesBeforeTax: adminCharges,
      adminChargesTaxAmount: "",
    };

    if (!unit) return;

    if (annualAmount) {
      const calculatedContractAmount = calculateContractAmount({
        annualAmount,
        startDate: agreementStartDate,
        endDate: agreementEndDate,
        gracePeriodDays,
      });

      data.contractAmount = calculatedContractAmount;
      data.amountBeforeTax = calculatedContractAmount;
    }

    const amountToTax = data.contractAmount;

    if (amountOfTax && tax) {
      const parameters = {
        amount: amountToTax,
        amountOfTax,
        tax,
        taxAmount: "",
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    if (amountOfTax && adminChargesTax) {
      const parameters = {
        amount: adminCharges,
        amountOfTax,
        tax: adminChargesTax,
        taxAmount: "",
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.adminChargesTaxAmount = taxAmount;
      data.adminChargesBeforeTax = principalAmount;
    }

    data.totalAdminCharges =
      Number(data.adminChargesBeforeTax || 0) + Number(data.adminChargesTaxAmount || 0);

    data.totalContractAmount = Number(data.amountBeforeTax) + Number(data.taxAmount || 0);

    data.totalContractAmount = formatDecimalValues(data.totalContractAmount);

    const totalUnitArea = unit.reduce(
      (total, currentValue) => total + Number(currentValue.totalUnitArea),
      0
    );

    if (totalUnitArea > 0) {
      const ratePerUnitOfArea = formatDecimalValues(annualAmount / totalUnitArea);
      data.ratePerUnitOfArea = ratePerUnitOfArea;
    }
    calculateContractAmountInUnitAnnualAmount(data);
    ref.current.setFormState(data);
    if (!isEditing) {
      setPaymentDetail(key, value);
    }
  };

  const setAgreementEndDate = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      duration, noOfDuration, gracePeriodDays, agreementStartDate, durationType
    } = formState;

    if (!duration || !noOfDuration || !agreementStartDate || durationType?.value !== "Default") {
      return;
    }

    const agreementEndDate = calculateDefaultEndDate({
      startDate: agreementStartDate,
      duration,
      noOfDuration,
    });

    const agreementEndDateWithGracePeriod = addGracePeriodToEndDate({
      endDate: agreementEndDate,
      gracePeriodDays,
    });

    const agreementEndDateWithoutGracePeriod = calculateEndDateWithoutGracePeriod(
      agreementEndDateWithGracePeriod,
      gracePeriodDays
    );

    const noOfDays = differenceInDays(agreementEndDateWithoutGracePeriod, agreementStartDate) + 1;

    const data = {
      agreementEndDate: agreementEndDateWithGracePeriod,
      agreementEndDateWithoutGracePeriod,
      noOfDays,
    };

    ref.current.setFormState(data);
    setTaxAndTotal("agreementEndDate", agreementEndDateWithGracePeriod);
  };

  const setDuration = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { agreementStartDate, agreementEndDate, gracePeriodDays } = formState;

    if (!agreementStartDate || !agreementEndDate) return;

    const agreementEndDateWithoutGracePeriod = calculateEndDateWithoutGracePeriod(
      agreementEndDate,
      gracePeriodDays
    );

    const noOfDays = differenceInDays(agreementEndDateWithoutGracePeriod, agreementStartDate) + 1;

    const { years, months, days } = intervalToDuration({
      start: agreementStartDate,
      end: addDays(agreementEndDate, 1),
    });

    const data = {
      agreementEndDateWithoutGracePeriod,
      noOfDays,
      durationYears: `${years}`,
      durationMonths: `${months}`,
      durationDays: `${days}`,
    };

    ref.current.setFormState(data);
    setTaxAndTotal(agreementEndDate, "agreementEndDate");
  };

  const setDurationType = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const { durationType } = formState;

    if (!value) {
      return;
    }

    if (durationType.value === "Default") {
      setAgreementEndDate(key, value);
      return;
    }

    if (durationType.value === "Custom") {
      setDuration(key, value);
    }
  };

  const setTotalUtilityCharge = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const {
      addUtilityCharge,
      utilityChargeDetail,
      utilityTax: tax,
      utilityAmountOfTax: amountOfTax,
      noOfUtilityPayments,
    } = formState;

    if (!addUtilityCharge || !utilityChargeDetail || !utilityChargeDetail.length) {
      const data = {
        utilityChargeDetail: [],
        utilityCharge: 0,
        utilityAmountBeforeTax: 0,
        utilityTaxAmount: 0,
        totalUtilityCharge: 0,
        noOfUtilityPayments: undefined,
      };

      ref.current.setFormState(data);
      setTaxAndTotal("totalUtilityCharge", 0);
      return;
    }

    const utilityCharge = utilityChargeDetail.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    let amount = formatDecimalValues(utilityCharge);
    let taxAmount = 0;

    if (tax && amountOfTax) {
      const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax({
        amount,
        amountOfTax,
        tax,
        taxAmount: "",
      });
      amount = principalAmount;
      taxAmount = calculatedTaxAmount;
    }

    const totalUtilityCharge = Number(amount) + Number(taxAmount);

    const data = {
      utilityCharge,
      utilityAmountBeforeTax: amount,
      utilityTaxAmount: taxAmount,
      totalUtilityCharge,
    };

    if (utilityChargeDetail.length) {
      data.noOfUtilityPayments = noOfUtilityPayments;
    }

    ref.current.setFormState(data);
    setTaxAndTotal("totalUtilityCharge", data.totalUtilityCharge);
  };

  const getAdjustmentAmount = ({
    adjustAmountIncreaseType,
    adjustAmount,
    adjustAmountPercentage,
    prevTotal,
  }) => {
    if (adjustAmountPercentage && adjustAmountIncreaseType?.value === "Percentage") {
      const amountAdjusted = prevTotal * (adjustAmountPercentage / 100);
      return formatDecimalValues(amountAdjusted);
    }

    return adjustAmount;
  };

  const setAdjustAmountDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      annualAmount,
      adjustAmountType,
      adjustAmount,
      adjustAmountDuration,
      agreementStartDate,
      adjustAmountPercentage,
      adjustAmountIncreaseType,
    } = formState;

    if (!adjustAmount || !adjustAmountType) {
      if (!adjustAmountDuration && !adjustAmountPercentage) {
        ref.current.setFormValue("adjustAmountDetail", []);
        return;
      }
    }

    if (!annualAmount || !agreementStartDate) {
      return;
    }
    if (adjustAmountType.value === "Yearly") {
      const adjustAmountDetail = [];

      for (let i = 0; i < adjustAmountDuration; i += 1) {
        const date = addYears(agreementStartDate, i + 1);
        const prevTotal = i ? adjustAmountDetail[i - 1].total : annualAmount;

        const adjustedAmount = getAdjustmentAmount({
          adjustAmountIncreaseType,
          adjustAmount,
          adjustAmountPercentage,
          prevTotal,
        });

        const total = Number(prevTotal) + Number(adjustedAmount);

        adjustAmountDetail.push({
          adjustType: {
            label: "Increase",
            value: "Increase",
          },
          percentage: adjustAmountPercentage,
          amount: adjustedAmount,
          date,
          total,
        });
      }

      ref.current.setFormValue("adjustAmountDetail", adjustAmountDetail.reverse());
    }
  };

  const setExchangeRate = (value) => {
    if (!value) {
      ref.current.setFormValue("exchangeRate", "");
    }

    ref.current.setFormValue("exchangeRate", exchangeRate[value.label] || 5);
  };

  const setIncreasedRent = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const { rentChangeType, rentChangePercentage, prevAnnualAmount } = formState;

    const { newAmount, changedAmount } = calculateRentChange({
      type: rentChangeType,
      percentage: rentChangePercentage,
      amount: prevAnnualAmount,
    });

    const data = {
      increasedRentAmount: changedAmount,
      annualAmount: newAmount,
    };

    ref.current.setFormState(data);
    setTaxAndTotal("annualAmount", data.annualAmount);
  };

  const setReservationData = (key, value) => {
    const data = formatReservation(value);
    ref.current.setFormState(data);
    setUnitData(data.unit);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant": {
        setState({
          ...state,
          [key]: value,
        });

        if (!value) {
          ref.current.setFormState(reservationInitialState);
        }
        break;
      }

      case "reservation": {
        setReservationData(key, value);
        break;
      }

      case "durationType":
        setDurationType(key, value);
        return;

      case "gracePeriodDays":
      case "agreementStartDate":
      case "duration":
      case "noOfDuration":
        setAgreementEndDate(key, value);
        break;

      case "agreementEndDate":
        setDuration(key, value);
        break;

      case "addUtilityCharge":
      case "utilityChargeDetail":
      case "utilityTax":
        setTotalUtilityCharge(key, value);
        break;

      case "noOfPayments":
      case "noOfUtilityPayments":
        generatePayments(key, value);
        break;

      case "building":
        setBuildingData(key, value);
        break;

      case "unit":
        setUnitData(value);
        setUnitAnnualAmount(value);
        break;

      case "tax":
      case "securityDeposit":
      case "adminCharges":
      case "adminChargesTax":
      case "annualAmount": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTaxAndTotal(key, value);
        break;
      }

      case "utilityAmountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTotalUtilityCharge(key, value);
        break;
      }

      case "adjustAmount":
      case "adjustAmountType":
      case "adjustAmountDuration":
      case "adjustAmountPercentage":
      case "adjustAmountIncreaseType":
        setAdjustAmountDetail(key, value);
        break;

      case "currency":
        setExchangeRate(value);
        break;

      case "rentChangeType":
      case "rentChangePercentage":
        setIncreasedRent(key, value);
        break;

      default:
        break;
    }
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const { contractAmount, amountOfTax } = formState;
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "utilityChargeDetail") {
      switch (key) {
        case "utilityType": {
          parentFieldState[key] = value;
          const { utilityType } = parentFieldState;

          if (utilityType) {
            const { amountType, amount, amountPercentage } = utilityType;
            if (amountType === "Amount") {
              parentFieldState.amount = amount;
            } else if (amountType === "Percentage" && contractAmount) {
              const utilityAmount = (contractAmount * amountPercentage) / 100;
              parentFieldState.amount = utilityAmount;
            }
          } else {
            parentFieldState.amount = 0;
          }
          break;
        }

        default:
          break;
      }
    }

    if (parentField === "paymentDetail") {
      switch (key) {
        case "paymentType":
        case "amount":
        case "tax": {
          parentFieldState[key] = value;
          const {
            paymentReceipt, amount, tax, paymentType
          } = parentFieldState;

          const data = {
            principalAmount: amount,
            taxAmount: 0,
          };

          if (tax && amount) {
            const { principalAmount, taxAmount } = calculateTax({
              amount,
              amountOfTax,
              tax,
              taxAmount: "",
            });

            data.taxAmount = taxAmount;
            data.principalAmount = principalAmount;
          }

          const paymentAmount = data.principalAmount;
          const totalPaymentAmount = Number(data.principalAmount) + Number(data.taxAmount);

          if (paymentType?.value === "Rent") {
            parentFieldState.rentAmount = paymentAmount;
          }

          if (paymentType?.value === "Utility") {
            parentFieldState.utilityAmount = paymentAmount;
          }

          if (paymentType?.value === "SecurityDeposit") {
            parentFieldState.securityDepositAmount = paymentAmount;
          }

          if (paymentType?.value === "AdminCharges") {
            parentFieldState.adminChargesAmount = paymentAmount;
          }

          if (!paymentReceipt || isEmpty(paymentReceipt)) {
            parentFieldState.openBalance = totalPaymentAmount;
          }

          parentFieldState.amount = paymentAmount;
          parentFieldState.taxAmount = data.taxAmount;
          parentFieldState.totalAmount = totalPaymentAmount;

          break;
        }

        default:
          break;
      }
    }

    if (parentField === "unitAnnualAmount") {
      if (key === "annualAmount") {
        const { unitAnnualAmount } = formState;
        parentFieldState[key] = value;
        const newAnnualAmount = unitAnnualAmount.reduce(
          (sum, item) => sum + Number(item.annualAmount || 0),
          0
        );
        setTaxAndTotal("annualAmount", newAnnualAmount);
      }
    }
  };

  const getFormTitle = useCallback(() => {
    if (isRenewingContract) {
      return "Renew Contract";
    }

    return "";
  }, []);

  const readOnlyFields = () => {
    if (isRenewingContract) {
      return ["contractType", "agreementStartDate"];
    }

    return [];
  };

  const onNextSave = (currentStep) => {
    if (currentStep === 1) {
      const formState = ref.current.getState();
      const paymentDetail = formState?.paymentDetail;
      const totalRentAmount = paymentDetail
        ?.filter((item) => item.paymentType.value === "Rent")
        .reduce((sum, item) => sum + (item.totalAmount || 0), 0);
      const totalSecurityAmount = paymentDetail
        ?.filter((item) => item.paymentType.value === "SecurityDeposit")
        .reduce((sum, item) => sum + (item.totalAmount || 0), 0);
      const totalUtilityAmount = paymentDetail
        ?.filter((item) => item.paymentType.value === "Utility")
        .reduce((sum, item) => sum + (item.totalAmount || 0), 0);
      const totalAdminChargesAmount = paymentDetail
        ?.filter((item) => item.paymentType.value === "AdminCharges")
        .reduce((sum, item) => sum + (item.totalAmount || 0), 0);
      let checks = [
        {
          name: "Rent Amount",
          total: totalRentAmount,
          formField: formState?.totalContractAmount,
          formFieldLabel: "Total Contract Amount",
        },
        {
          name: "Security Deposit",
          total: totalSecurityAmount,
          formField: formState?.securityDeposit,
          formFieldLabel: "Security Deposit",
        },
        {
          name: "Utility Charge",
          total: totalUtilityAmount,
          formField: formState?.totalUtilityCharge,
          formFieldLabel: "Total Other Charges",
        },
        {
          name: "Admin Charges",
          total: totalAdminChargesAmount,
          formField: formState?.totalAdminCharges,
          formFieldLabel: "Total Admin Charges",
        },
      ];

      if (formState.contractInitiationType.value === "Renewed") {
        checks = checks.filter((item) => item.name !== "Security Deposit");
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < checks.length; i++) {
        const {
          name, total, formField, formFieldLabel
        } = checks[i];
        const difference = Math.abs(Math.round(total || 0) - Math.round(formField || 0));
        if (difference !== 0) {
          toast.error(
            `${name} is not equal to the ${formFieldLabel} amount. Difference ${formatCurrency(
              difference
            )}`
          );
          return false;
        }
      }
      return true;
    }
    return true;
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        title={getFormTitle()}
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("ContractObjectName")}
        showHeader
        showLinkedViews
        onSuccess={(recordId) => {
          redirect(-1, {
            recordId,
            success: true,
          });
        }}
        navigate={false}
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        readOnlyFields={readOnlyFields()}
        onNextSave={onNextSave}
      />
    </BoxedContent>
  );
}

export default ContractForm;
