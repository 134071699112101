import React, {
  useState, useEffect, useCallback, useMemo, useRef
} from "react";
import { useSelector } from "react-redux";
import { kebabCase, startCase } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Label, Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";
import qs from "qs";
import { camelize, pascalize } from "humps";
import { useUrlSearchParams, useModal } from "@/hooks";
import showToast from "@/utils/toast/helpers";
import {
  Header,
  BoxedContent,
  StatusWithProgressbar,
  AttachmentsList,
  SvgIcon,
  IconButton,
} from "@/components/common";
import { AlertModal } from "@/components/modals";
import {
  WorkflowActivityDrawer,
  UploadImageForm,
  TaskRemarksForm
} from "@/components/workflow";
import {
  P2PWorkflowContainer,
  FixedAssetDisposalWorkflowFormContainer,
} from "@/components/finance";
import {
  MoveOutWorkflowFormContainer,
  MoveInWorkflowFormContainer,
} from "@/components/leasing";
import {
  FitOutWorkflowFormContainer,
  PettyCashWorkflowFormContainer,
  SupervisorPettyCashWorkflowFormContainer,
  PettyCashClaimWorkflowFormContainer,
} from "@/components/facility";
import { DynamicObjectActivityDrawer } from "@/components/dynamic";
import { getWorkflowSteps, updateTask, getTaskById } from "@/api/workflow/workflowApi";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { TASK_STATUSES, TASK_TYPES, APPROVED_STATUSES } from "@/components/workflow/constants";

import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import workflowMap from "@/utils/maps/workflowMap";
import { selectActiveApp } from "@/store/appSlice";
import { getObjectNameFromId } from "@/utils/dynamic/helpers";
import { handleDrawer } from "@/utils/modal/helpers";

import "reactflow/dist/style.css";

function RecordCard({ objectName, recordData }) {
  const getRecordInfo = useCallback(() => {
    const { id: recordId } = recordData;

    switch (objectName) {
      case dynamicObjectMap.get("WorkOrderObjectName"): {
        const { number } = recordData;

        return {
          name: "Work Order",
          id: recordId,
          label: number,
          link: `/facility-management/work-order/details/${recordId}`,
        };
      }

      default: {
        const { number } = recordData;

        return {
          name: startCase(objectName),
          id: recordId,
          label: number,
          link: handleDrawer(recordData?.id, null, false, "dynamicObjectDrawers"),
        };
      }
    }
  }, [objectName, recordData]);

  if (!objectName || !recordData) return null;

  const recordInfo = getRecordInfo();

  if (!recordInfo) {
    return null;
  }

  const { name, label, link } = recordInfo;
  return (
    <div className="record-card">
      <p>
        {name}
        {": "}
        <Link to={link}>{label}</Link>
      </p>
    </div>
  );
}

RecordCard.propTypes = {
  objectName: PropTypes.string.isRequired,
  recordData: PropTypes.object.isRequired,
};

const UploadImageFormContainer = React.forwardRef(
  ({
    task, setIsLoading, onSuccess, recordData, steps
  }, ref) => {
    const renderForm = () => {
      if (!task) {
        return null;
      }

      if (
        task?.workflowName === workflowMap.get("LeasingFlow") &&
        task?.currentStep === "Upload Sign Copy" &&
        task.status === TASK_STATUSES.INPROGRESS
      ) {
        return (
          <>
            {task?.taskType !== TASK_TYPES.APPROVAL && (
              <UploadImageForm
                ref={ref}
                data={[{ name: "Attachment", required: true }]}
                parentId={task.preRecordId}
                objectName={dynamicObjectMap.get("ContractObjectName")}
                setIsLoading={setIsLoading}
                onSuccess={onSuccess}
              />
            )}
            {recordData?.attachment?.length ? (
              <div className="col-12">
                <Label labelFor="Attachments" label="Signed Copy" />
                <AttachmentsList attachments={recordData.attachment} />
              </div>
            ) : null}
          </>
        );
      }

      if (
        task?.workflowName === workflowMap.get("LeasingFlow") &&
        task?.currentStep === "Upload Attested Contract" &&
        task?.status === TASK_STATUSES.INPROGRESS
      ) {
        const contractId =
          steps[steps.findIndex((step) => step.step === "Create Tenancy Contract")].recordId;

        const renderMultipleFields = () => {
          const fieldNames = [
            { name: "TawtheeqAttachments", required: true },
            { name: "UtilityCertificateAttachments", required: false },
            { name: "NOCAttachments", required: false },
            { name: "OtherAttachments", required: false },
          ];
          return (
            <UploadImageForm
              ref={ref}
              data={fieldNames}
              parentId={contractId}
              objectName={dynamicObjectMap.get("ContractObjectName")}
              setIsLoading={setIsLoading}
            />
          );
        };

        return (
          <>
            {renderMultipleFields()}
            {recordData?.attachment?.length ? (
              <div className="col-12">
                <Label labelFor="Attachments" label="Signed Copy" />
                <AttachmentsList attachments={recordData.attachment} />
              </div>
            ) : null}
          </>
        );
      }

      if (
        task?.workflowName === workflowMap.get("RenewContractFlow") &&
        task?.currentStep === "Upload Attested Copies" &&
        task?.status === TASK_STATUSES.INPROGRESS
      ) {
        const renderMultipleFields = () => {
          const fieldNames = [
            { name: "TawtheeqAttachments", required: true },
            { name: "UtilityCertificateAttachments", required: false },
            { name: "NOCAttachments", required: false },
            { name: "OtherAttachments", required: false },
          ];
          return (
            <UploadImageForm
              ref={ref}
              data={fieldNames}
              parentId={task.recordId}
              objectName={dynamicObjectMap.get("ContractObjectName")}
              setIsLoading={setIsLoading}
              onSuccess={onSuccess}
            />
          );
        };

        return (
          <>
            {renderMultipleFields()}
            {recordData?.attachment?.length ? (
              <div className="col-12">
                <Label labelFor="Attachments" label="Signed Copy" />
                <AttachmentsList attachments={recordData.attachment} />
              </div>
            ) : null}
          </>
        );
      }

      if (
        task?.workflowName === workflowMap.get("MoveOutFlow") &&
        task?.currentStep === "Upload Cancellation Documents" &&
        task?.status === TASK_STATUSES.INPROGRESS
      ) {
        const renderMultipleFields = () => {
          const fieldNames = [
            { name: "TawtheeqAttachments", required: true },
            { name: "NOCAttachments", required: false },
            { name: "OtherAttachments", required: false },
          ];
          return (
            <UploadImageForm
              ref={ref}
              data={fieldNames}
              parentId={task.recordId}
              objectName={task.objectName}
              setIsLoading={setIsLoading}
              onSuccess={onSuccess}
            />
          );
        };

        return <>{renderMultipleFields()}</>;
      }

      return null;
    };

    return <div>{renderForm()}</div>;
  }
);

UploadImageFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const TaskButtonsContainer = React.forwardRef(
  (
    {
      task,
      isLoading,
      updateMutation,
      setIsLoading,
      recordData,
      workflowSteps,
      searchParameters,
      isTaskFetched,
      remarks,
      setRemarks,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const activeApp = useSelector(selectActiveApp);
    const taskRemarksButtonRef = useRef(null);
    const { isOpen, closeModal, openModal } = useModal(false);

    const tasksRoute = `/${kebabCase(activeApp?.value)}/tasks`;

    const getActionButtonText = useCallback(
      (taskType, label) => {
        switch (taskType) {
          case TASK_TYPES.CREATE:
            return label || "Create";

          case TASK_TYPES.SUBMIT:
            return label || "Submit";

          case TASK_TYPES.UPDATE:
            return label || "Update";

          case TASK_TYPES.VALIDATE:
            return label || "Valid";

          default:
            return label || "Approve";
        }
      },
      [task]
    );

    const getCancelButtonText = useCallback(
      (taskType) => {
        switch (taskType) {
          case TASK_TYPES.CREATE:
          case TASK_TYPES.SUBMIT:
          case TASK_TYPES.UPDATE:
            return "Cancel";

          case TASK_TYPES.VALIDATE:
            return "Invalid";

          default:
            return "Reject";
        }
      },
      [task]
    );

    const getActionButtonStatus = useCallback(
      (taskType, isCancelled) => {
        switch (taskType) {
          case TASK_TYPES.VALIDATE:
            if (isCancelled) {
              return TASK_STATUSES.INVALID;
            }
            return TASK_STATUSES.VALID;

          default:
            if (isCancelled) {
              return TASK_STATUSES.REJECTED;
            }
            return TASK_STATUSES.APPROVED;
        }
      },
      [task]
    );

    const handleAction = useCallback(
      (isCancelled) => {
        const value = getActionButtonStatus(task?.taskType, isCancelled);
        switch (task?.taskType) {
          case TASK_TYPES.CREATE:
          case TASK_TYPES.SUBMIT: {
            if (isCancelled) {
              navigate(tasksRoute);
              return;
            }

            switch (task?.objectName) {
              case dynamicObjectMap.get("VisualInspectionObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  moveOutRequest: task?.preRecordId,
                  contract: recordData?.contract?.id
                };

                navigate(`/facility-management/visual-inspection/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("PurchaseOrderObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  requestForQuotation: task?.preRecordId,
                };

                navigate(`/finance/purchase-order/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("ContractObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  tenant: task?.preRecordId,
                };
                navigate(`/leasing/contract/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("GoodsReceiptNoteObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  purchaseOrder: task?.preRecordId,
                };

                navigate(`/finance/goods-receipt-note/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("PurchaseInvoiceObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  goodsReceiptNote: task?.preRecordId,
                };

                navigate(`/finance/purchase-invoice/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("PaymentReceiptObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                };

                if (task.preObjectName === dynamicObjectMap.get("ContractObjectName")) {
                  params.contract = task?.preRecordId;
                }

                if (task.preObjectName === dynamicObjectMap.get("ReservationObjectName")) {
                  params.reservation = task?.preRecordId;
                }

                navigate(`/finance/payment-receipt/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("WorkOrderObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  serviceRequest: task?.preRecordId,
                };

                navigate(`/facility-management/work-order/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("MoveInCheckListObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  moveInRequest: task?.preRecordId,
                };

                navigate(`/leasing/move-in-check-list/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("GeneralReceiptObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                };

                if (task.preObjectName === dynamicObjectMap.get("GeneralReceiptObjectName")) {
                  params.contractTermination = task?.preRecordId;
                }

                if (task.preObjectName === dynamicObjectMap.get("ReservationObjectName")) {
                  params.reservation = task?.preRecordId;
                }

                if (task.preObjectName === dynamicObjectMap.get("DisposalObjectName")) {
                  params.disposal = recordData?.id;
                }

                navigate(`/finance/general-receipt/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("PaymentObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                };

                if (task.preObjectName === dynamicObjectMap.get("PaymentObjectName")) {
                  params.contractTermination = task?.preRecordId;
                } else {
                  params[camelize(task?.preObjectName)] = task?.preRecordId;
                }

                navigate(`/finance/payment/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("ItemObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  [camelize(task?.preObjectName)]: task?.preRecordId,
                };

                navigate(`/finance/${kebabCase(dynamicObjectMap.get("ItemObjectName"))}/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("ContractTerminationObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                  visualInspection:
                    workflowSteps.data[
                      workflowSteps.data.findIndex((step) => step.step === "Inspection")
                    ].recordId,
                  moveOutRequest: task.preRecordId,
                };

                navigate(`/leasing/contract-termination/new?${qs.stringify(params)}`);
                break;
              }

              case dynamicObjectMap.get("CustomerObjectName"): {
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowRunId,
                };

                navigate(`/sales/customer/new?${qs.stringify(params)}`);
                break;
              }

              default: {
                try {
                  let objectName = task?.objectName;

                  if (
                    task?.preObjectName === dynamicObjectMap.get("RequestForQuotationObjectName") &&
                    task?.currentStep === "Request for Quote"
                  ) {
                    objectName = "Quotation";
                  }

                  const submitButton = document.getElementById(
                    `dynamic-form-submit-button-${kebabCase(objectName)}`
                  );
                  submitButton.click();

                  if (task?.currentStep === "Upload Attested Contract") {
                    if (ref.current) {
                      ref.current.click();
                    }
                  }
                } catch (error) {
                  setIsLoading(false);
                }
                break;
              }
            }

            break;
          }

          case TASK_TYPES.UPDATE: {
            if (isCancelled) {
              navigate(tasksRoute);
              break;
            }

            if (
              task?.workflowName === workflowMap.get("VendorFlow") &&
              task?.currentStep === "Propose Inspection Time"
            ) {
              const params = {
                taskId: task.id,
              };

              const jobId = recordData?.id;

              navigate(
                `/facility-management/work-order-jobs/${jobId}/propose-time?${qs.stringify(params)}`
              );
              break;
            }

            if (
              task?.workflowName === workflowMap.get("VendorFlow") &&
              task?.currentStep === "Schedule Inspection"
            ) {
              const params = {
                jobId: recordData?.id,
                taskId: task.id,
              };
              const workOrderId = recordData?.workOrder?.id;

              navigate(`/facility-management/work-order/${workOrderId}?${qs.stringify(params)}`);
              break;
            }

            if (
              task?.workflowName === workflowMap.get("FixedAssetTransferFlow") &&
              task?.currentStep === "Transfer Asset"
            ) {
              const params = {
                taskId: task.id,
                workflowId: task.workflowId,
                [camelize(task?.preObjectName)]: task?.preRecordId
              };

              const recordId = recordData?.asset?.id;

              navigate(
                `/finance/item/${recordId}?${qs.stringify(params)}`
              );
              break;
            }

            if (
              task?.workflowName === workflowMap.get("SupplierFlow") &&
              task?.currentStep === "Approve Supplier"
            ) {
              const params = {
                taskId: task.id,
                workflowId: task.workflowId
              };

              navigate(
                `/facility-management/supplier/${task?.preRecordId}?${qs.stringify(params)}`
              );
              break;
            }

            if (
              task?.currentStep === "Upload Sign Copy" ||
              task?.currentStep === "Upload Cancellation Documents" ||
              task?.currentStep === "Upload Attested Copies"
            ) {
              if (ref.current) {
                ref.current.click();
                break;
              }
            }
            try {
              const submitButton = document.getElementById(
                `dynamic-form-submit-button-${kebabCase(task?.objectName)}`
              );

              if (submitButton) {
                submitButton.click();
              } else {
                setIsLoading(false);
                const params = {
                  taskId: task.id,
                  workflowId: task.workflowId,
                  redirect: "task",
                  recordId: task.recordId
                };
                navigate(
                  `/${kebabCase(activeApp.value)}/${kebabCase(task.objectName)}/${task?.recordId}?${qs.stringify(params)}`
                );
              }
              break;

            } catch (error) {
              setIsLoading(false);
            }

            updateMutation.mutate({
              taskId: task?.id,
              status: value,
            });
            break;
          }

          default: {
            if (isCancelled && value === TASK_STATUSES.REJECTED) {
              if (!remarks) {
                openModal();
                setIsLoading(false);
                break;
              }

              updateMutation.mutate({
                taskId: task?.id,
                status: value,
              });
              break;
            }
            try {
              const submitButton = document.getElementById(
                `dynamic-form-submit-button-${kebabCase(task?.objectName)}`
              );
              submitButton.click();
            } catch (error) {
              setIsLoading(false);
            }

            updateMutation.mutate({
              taskId: task?.id,
              status: value,
            });
            break;
          }
        }
      },
      [remarks]
    );

    useEffect(() => {
      if (isTaskFetched && searchParameters?.reject && searchParameters?.recordId) {
        setIsLoading(true);
        handleAction(searchParameters?.recordId);
      }
    }, [searchParameters, isTaskFetched]);

    const onConfirm = () => {
      taskRemarksButtonRef.current.click();
    };

    const handleConfirm = () => {
      handleAction(true);
    };

    return (
      <div className="buttons-container">
        <AlertModal
          icon="file-check-stroke-icon"
          title="Rejection Remarks"
          subtitle="Add your remarks for rejecting the current task"
          isOpen={isOpen}
          onClose={closeModal}
          onConfirm={onConfirm}
          size="large"
          isLoading={isLoading}
        >
          <TaskRemarksForm
            ref={taskRemarksButtonRef}
            remarks={remarks}
            setRemarks={setRemarks}
            onSubmit={handleConfirm}
            required
          />
        </AlertModal>
        {getCancelButtonText(task?.taskType) !== "Cancel" && (
          <Button
            small
            bordered
            onClick={() => navigate(tasksRoute)}
            className="btn-with-icon"
            testId="Task-Cancel-Button"
          >
            Cancel
          </Button>
        )}
        {!APPROVED_STATUSES.includes(task?.status) && (
          <>
            <Button
              small
              bordered
              disabled={isLoading}
              onClick={() => handleAction(true)}
              className="btn-with-icon"
              testId="Task-Cancel-Button"
            >
              {getCancelButtonText(task?.taskType)}
            </Button>
            <Button
              small
              loading={isLoading}
              disabled={task?.status !== TASK_STATUSES.INPROGRESS}
              onClick={() => handleAction(false)}
              className="btn-with-icon"
              testId="Task-Submit-Button"
            >
              {getActionButtonText(task?.taskType, task.label)}
            </Button>
          </>
        )}
      </div>
    );
  }
);

TaskButtonsContainer.propTypes = {
  task: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  updateMutation: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  workflowSteps: PropTypes.object.isRequired,
};

function WorkflowSteps({ steps, activeStep }) {
  const activeStepIndex = steps.findIndex((step) => step.step === activeStep);

  return (
    <div className="status-cards">
      {steps?.map((step, index) => (
        <StatusWithProgressbar
          key={`step-${index}`}
          title={step.step}
          progressCount={step.status}
          isPassed={activeStepIndex > index}
        />
      ))}
    </div>
  );
}

WorkflowSteps.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default function Task() {
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  const [remarks, setRemarks] = useState("");
  const { taskId: id } = useParams();
  const navigate = useNavigate();
  const searchParameters = useUrlSearchParams();
  const activeApp = useSelector(selectActiveApp);
  const tasksRoute = `/${kebabCase(activeApp?.value)}/tasks`;
  const queryClient = useQueryClient();

  const {
    data: task,
    isInitialLoading: isLoadingTask,
    isFetched: isTaskFetched,
  } = useQuery(["task", id], () => getTaskById(id));

  const { data: workflowSteps, isInitialLoading: isLoadingSteps } = useQuery(
    ["workflow", task?.workflowRunId],
    () =>
      getWorkflowSteps({
        objectName: task?.recordId ? task?.objectName : task?.preObjectName,
        workflowId: task?.workflowRunId,
      }),
    {
      enabled: isTaskFetched,
    }
  );

  const { data: recordData, isInitialLoading: isLoadingRecord } = useQuery(
    [
      kebabCase(task?.recordId ? task?.objectName : task?.preObjectName),
      task?.recordId || task?.preRecordId,
    ],
    () =>
      getDynamicObjectRecordById(
        kebabCase(task?.recordId ? task?.objectName : task?.preObjectName),
        task?.recordId || task?.preRecordId
      ),
    {
      enabled: isTaskFetched,
    }
  );

  const updateMutation = useMutation(
    ({ taskId, status, recordId }) =>
      updateTask({
        id: taskId,
        status,
        recordId,
        remarks: remarks || status,
      }),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: (_, variables) => {
        const { status } = variables;

        if (status) {
          showToast(`${status} successfully`, "success");
        } else {
          showToast("Approved successfully", "success");
        }

        navigate(tasksRoute);
      },
    }
  );

  const getTaskStatus = useCallback(() => {
    switch (task?.taskType) {
      case TASK_TYPES.SUBMIT:
        return TASK_STATUSES.SUBMITTED;

      case TASK_TYPES.CREATE:
        return TASK_STATUSES.COMPLETED;

      case TASK_TYPES.UPDATE:
        return TASK_STATUSES.UPDATED;

      case TASK_TYPES.VALIDATE:
        return TASK_STATUSES.VALID;

      default:
        return TASK_STATUSES.APPROVED;
    }
  }, [task?.taskType]);

  const onSuccess = (recordId) => {
    const payload = {
      taskId: id,
      status: getTaskStatus(),
    };

    if (recordId) {
      payload.recordId = recordId;
    }

    updateMutation.mutate(payload);
    setIsLoading(false);

    if (payload.status === TASK_STATUSES.UPDATED) {
      queryClient.invalidateQueries({
        queryKey: [
          kebabCase(task?.recordId ? task?.objectName : task?.preObjectName),
          task?.recordId || task?.preRecordId,
        ]
      });
    }
  };

  const activityTimeline = useMemo(() => {
    if (!workflowSteps?.data) return [];

    return workflowSteps?.data?.map((step) => ({
      name: `${step.step}:`,
      title:
        step.status === "Completed" ?
          `View ${startCase(pascalize(getObjectNameFromId(step.recordId)))}` :
          "",
      link: handleDrawer(step.recordId, null, false, "dynamicObjectDrawers"),
      tags:
        step.status === "Completed" ?
          [{ text: startCase(step.status), variant: "success" }] :
          [{ text: startCase(step.status), variant: "primary" }],
    }));
  }, [workflowSteps]);

  useEffect(() => {
    if (isTaskFetched && searchParameters?.success && searchParameters?.recordId) {
      setIsLoading(true);
      onSuccess(searchParameters?.recordId);
    }
  }, [searchParameters, isTaskFetched]);
  return (
    <BoxedContent>
      {!isLoadingTask && (
        <Header
          showBreadcrumb
          leftContent={<h1>{task?.currentStep}</h1>}
          rightContent={(
            <div className="d-flex">
              <IconButton
                className="icon-button-with-text mx-2"
                onClick={() => navigate("task-workflow")}
              >
                <SvgIcon icon="vat-summary-report" />
                <span>Workflow Chart</span>
              </IconButton>
              <IconButton
                className="icon-button-with-text"
                onClick={() =>
                  handleDrawer(task?.workflowRunId, navigate, false, "objectActivityDrawers")}
              >
                <SvgIcon icon="filter-lines-stroke-icon" />
                <span>Activity Log</span>
              </IconButton>
            </div>
          )}
        />
      )}
      {isLoadingTask || isLoadingRecord || isLoadingSteps ? (
        <Loader />
      ) : (
        <div className="view-task-container">
          <WorkflowActivityDrawer
            className="workflow-timeline-container"
            activities={activityTimeline}
          />
          <DynamicObjectActivityDrawer tabs={[{ label: "All", scope: task?.workflowRunId }]} />
          <WorkflowSteps activeStep={task.currentStep} steps={workflowSteps?.data ?? []} />
          {((workflowMap.get("P2P") === task?.workflowName) || (workflowMap.get("P2PFlow") === task?.workflowName)) && (
            <P2PWorkflowContainer
              task={task}
              recordData={recordData}
              onSuccess={onSuccess}
              setIsLoading={setIsLoading}
            />
          )}
          <RecordCard
            objectName={task?.recordId ? task?.objectName : task?.preObjectName}
            task={task}
            recordData={recordData}
            steps={workflowSteps?.data ?? []}
          />
          {workflowMap.get("PettyCashFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <PettyCashWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
              />
          )}
          {workflowMap.get("SupervisorPettyCashFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <SupervisorPettyCashWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
                onSuccess={onSuccess}
              />
          )}
          {workflowMap.get("PettyCashClaimFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <PettyCashClaimWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
                onSuccess={onSuccess}
              />
          )}
          {workflowMap.get("FitOutFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <FitOutWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
              />
          )}
          {workflowMap.get("MoveOutFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <MoveOutWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
                onSuccess={onSuccess}
              />
          )}
          {workflowMap.get("LeasingFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <MoveInWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
                steps={workflowSteps?.data ?? []}
                onSuccess={onSuccess}
              />
          )}
          {workflowMap.get("FixedAssetDisposalFlow") === task?.workflowName &&
            !APPROVED_STATUSES.includes(task?.status) && (
              <FixedAssetDisposalWorkflowFormContainer
                task={task}
                recordData={recordData}
                setIsLoading={setIsLoading}
                onSuccess={onSuccess}
              />
          )}
          <UploadImageFormContainer
            ref={ref}
            task={task}
            recordData={recordData}
            setIsLoading={setIsLoading}
            onSuccess={onSuccess}
            steps={workflowSteps?.data ?? []}
          />
          {task?.taskType === TASK_TYPES.APPROVAL && (
            <TaskRemarksForm
              remarks={remarks || task?.remarks}
              setRemarks={setRemarks}
              disabled={APPROVED_STATUSES.includes(task?.status)}
            />
          )}
          <TaskButtonsContainer
            ref={ref}
            isLoading={updateMutation.isLoading || isLoading || isLoadingTask}
            task={task}
            isTaskFetched={isTaskFetched}
            searchParameters={searchParameters}
            workflowSteps={workflowSteps}
            updateMutation={updateMutation}
            setIsLoading={setIsLoading}
            recordData={recordData}
            remarks={remarks}
            setRemarks={setRemarks}
          />
        </div>
      )}
    </BoxedContent>
  );
}
